import {type Package, type ProductApi} from '@apispec/101010tv';
import {type AxiosResponse} from 'axios';

export class ProductNameResolver {
  private productApi: (() => ProductApi) | null = null;
  private packages: Promise<AxiosResponse<Package[]>> | null = null;
  private packageMap: Record<string, number> | null = null;

  public async getNameForCode(code: string): Promise<string> {
    try {
      return (await this.loadData()).find((p) => p.productCode === code)?.name ?? code;
    } catch (e) {
      return code;
    }
  }

  public async getOrderMap(): Promise<Record<string, number>> {
    try {
      await this.loadData();
      return this.packageMap ?? {};
    } catch (e) {
      return {};
    }
  }

  public async getPackages(): Promise<Package[]> {
    return (await this.loadData());
  }

  public loadApis(productApi: () => ProductApi): void {
    this.productApi = productApi;
  }

  private async loadData(): Promise<Package[]> {
    if (this.productApi === null) {
      return [];
    }

    if (this.packages === null) {
      this.packages = this.productApi().getPackages();
    }

    const packages = (await this.packages).data;

    if (this.packageMap === null) {
      // Populate package map
      this.packageMap = {};
      packages.forEach((p, idx) => {
        if (!p.productCode) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.packageMap![p.productCode] = idx;
      });
    }

    return packages;
  }
}
